import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { LandsHolds } from '@/services/LandsHoldsService'
import { APIURL } from '@/components/Utility/APIBase'

type postDataType = { postStatus: boolean; postTitle: string; postContents: string; postReleased: string; postCategory: string; postViews: number; postId: number; }

@Options({
  props: {
    landsHoldsType: String,
    postData: { postStatus: true, postTitle: '', postContents: '', postReleased: '', postCategory: '', postViews: 0, postId: 0 }
  }
})
export default class landsHolds extends Vue {
  landsHoldsType!: string
  postData!: postDataType
  private landsHoldsData = null
  APIURL = APIURL

  @Watch('landsHoldsType')
  onUrlChange () : void {
    this.landsHoldsType && this.getLandsHoldsData()
  }

  public async getLandsHoldsData () : Promise<void> {
    await LandsHolds.LandsHoldsDataService.getLandsHolds(this.landsHoldsTypeParams)
      .then((response) => {
        this.landsHoldsData = response.data
      })
      .catch((error) => {
        console.log('ERROR', error)
      })
  }

  get landsHoldsTypeParams () {
    if (this.landsHoldsType === 'landsholdstruppen-herrer') {
      return 'landshold_landshold=herre'
    } else if (this.landsHoldsType === 'landsholdstruppen-damer') {
      return 'landshold_landshold=dame'
    } else if (this.landsHoldsType === 'landsholdstruppen-junior') {
      return 'landshold_landshold=junior'
    } else if (this.landsHoldsType === 'landsholdstruppen-paradart') {
      return 'landshold_landshold=paradart'
    } else {
      return ''
    }
  }

  get landsHoldsBrutto () {
    const landsHoldsBruttoData = (this as any).landsHoldsData?.filter((item: any) =>
      item.landshold_udtagelse === 'brutto' && item
    )
    return landsHoldsBruttoData
  }

  get landsHoldsLandshold () {
    const landsHoldsLandsholdData = (this as any).landsHoldsData?.filter((item: any) =>
      item.landshold_udtagelse === 'landshold' && item
    )
    return landsHoldsLandsholdData
  }

  get landsHoldsTalenttrup () {
    const landsHoldsTalenttrupData = (this as any).landsHoldsData?.filter((item: any) =>
      item.landshold_udtagelse === 'bobler' && item
    )
    return landsHoldsTalenttrupData
  }

  async mounted () : Promise<void> {
    this.getLandsHoldsData()
  }
}
