
import { Options, Vue } from 'vue-class-component'
import thePages from '@/components/Frontend/Pages/index.vue'

@Options({
  components: {
    thePages
  }
})
export default class PagesView extends Vue {}
